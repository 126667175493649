export const logo = require("../assets/logo.svg")
export const logout = require("../assets/logout.svg")
export const dashboard = require("../assets/dish.png")
export const catalogue = require("../assets/baker.png")
export const order = require("../assets/order.png")
export const orderhistory = require("../assets/orderhistory.svg")
export const notifications = require("../assets/notifications.svg")
export const summary = require("../assets/address.png")
export const legal = require("../assets/legal.svg")
export const support = require("../assets/support.png")
export const settings = require("../assets/settings.svg")
export const left = require("../assets/left.svg")
export const right = require("../assets/right.svg")
export const pay = require("../assets/pay.png")
export const user = require("../assets/user.png")
export const tick = require("../assets/tick-green.svg")
export const cross = require("../assets/cross-red.svg")