import React from "react";
// Customizable Area Start
import { Box, Tab, Tabs, Typography } from "@mui/material";


// Customizable Area End
import BakersController, {
  Props,
  
} from "./BakersController.web";

export default class Bakers extends BakersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
  
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box sx={{ width: '100%', height: "100%", overflowY:'hidden', overflowX:'hidden'}}>
         <Box sx={{ width: '100%', color: 'white', padding: 2 }}>
        <Tabs
          value={this.state.activeTab}
          onChange={this.handleTabChange}
          centered
          textColor="inherit"
          TabIndicatorProps={{
            style: { backgroundColor: 'yellow' },
          }}
          sx={{ display: 'flex', justifyContent: 'space-around' }}
        > 
         <Tab label="Dishes" sx={{ mx: 15 }} />
         <Tab label="Bakers" sx={{ mx: 15 }} />
        </Tabs>

        <Box sx={{ marginTop: 4 }}>
          {this.state.activeTab === 0 && (
            <Typography variant="h6">This is the content for Dishes.</Typography>
          )}
          {this.state.activeTab === 1 && (
            <Typography variant="h6">This is the content for Bakers.</Typography>
          )}
        </Box>
      </Box>
     
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
