import React from "react";
// Customizable Area Start

import { Sidebar, Menu, MenuItem, sidebarClasses } from 'react-pro-sidebar';
import { logo, logout, catalogue, orderhistory, notifications, summary, legal, settings, left, right, order, support } from "./assets"
import { styled, Typography } from "@mui/material";
import { Link, Route, Switch } from 'react-router-dom';
import Dishes from "./Dishes.web";


import UserDashboardController, {
    Props,
} from "./UserDashboardController.web";

export default class UserDashboard extends UserDashboardController {
    constructor(props: Props) {
        super(props);

    }

    render() {
        return (
            <div style={{ display: "flex", backgroundColor: "rgb(0, 0, 0)", height: "100vh" }}>
                <div style={{ height: "100%", display: 'flex' }}>
                    <Sidebar
                        collapsed={this.state.collapsed}
                        rootStyles={{
                            [`.${sidebarClasses.container}`]: {
                                backgroundColor: '#292524',
                                color: '#FFFFFF',
                                borderRadius: "8px"
                            },
                            border: "1px solid #57534E",
                            height: "800px",
                            width: '375px',
                            fontWeight: "bold",
                            borderRadius: '0px 0px 8px 0px'
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginBottom: '24px', marginTop: '20px' }}>
                            <img src={logo} alt="logo" />
                            {!this.state.collapsed && <Typography variant="h5" style={{ color: "#C2B30A", fontWeight: "700", textTransform: "uppercase", marginTop: '8px' }}>
                                AMATEUR BAKERS
                            </Typography>}
                        </div>
                        <StyledWrapper>
                            <Menu
                                menuItemStyles={{
                                    button: ({ level, active, disabled }) => {
                                        return {
                                            borderRadius: "8px",
                                            padding: "0px 8px",
                                            margin: "10px 14px",
                                            "&:hover": {
                                                backgroundColor: "#57534E",
                                                fontWeight: "bold",
                                            },
                                            cursor: 'pointer'
                                        };
                                    },
                                }}
                            >
                                <MenuItem
                                    className={this.state.activeMenuItem === "/menu/dishes" ? "active" : ""}
                                    onClick={() => this.handleMenuItemClick("/menu/dishes")}
                                    icon={<img src={catalogue} alt="catalogue" />}
                                    component={<Link to="/menu/dishes" />}
                                    data-test-id="dishes"
                                >
                                    <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                                        Dishes
                                    </Typography>
                                </MenuItem>

                                <MenuItem
                                    className={this.state.activeMenuItem === "/menu/bakers" ? "active" : ""}
                                    onClick={() => this.handleMenuItemClick("/menu/bakers")}
                                    icon={<img src={catalogue} alt="catalogue" />}
                                    component={<Link to="/menu/bakers" />}
                                    data-test-id="bakers"
                                >
                                    <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                                        Bakers
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    className={this.state.activeMenuItem === "/menu/userorderProgress" ? "active" : ""}
                                    onClick={() => this.handleMenuItemClick("/menu/userorderProgress")}
                                    icon={<img src={order} alt="catalogue" />}
                                    component={<Link to="/menu/userorderProgress" />}
                                    data-test-id="orderProgress"
                                >
                                    <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                                        Order in Progress
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    className={this.state.activeMenuItem === "/menu/userHistory" ? "active" : ""}
                                    onClick={() => this.handleMenuItemClick("/menu/userHistory")}
                                    icon={<img src={orderhistory} alt="orderhistory" />}
                                    component={<Link to="/menu/userHistory" />}
                                    data-test-id="orderHistory"
                                >
                                    <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                                        Orders History
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    className={this.state.activeMenuItem === "/menu/userAddress" ? "active" : ""}
                                    onClick={() => this.handleMenuItemClick("/menu/userAddress")}
                                    icon={<img src={summary} alt="summary" />}
                                    component={<Link to="/menu/userAddress" />}
                                    data-test-id="address"
                                >
                                    <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                                        My Addresses
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    className={this.state.activeMenuItem === "/menu/userNotifications" ? "active" : ""}
                                    onClick={() => this.handleMenuItemClick("/menu/userNotifications")}
                                    icon={<img src={notifications} alt="notifications" />}
                                    component={<Link to="/menu/userNotifications" />}
                                    data-test-id="notifications"
                                >
                                    <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                                        Notifications
                                    </Typography>
                                </MenuItem>

                                <hr style={{ color: "#57534E" }} />
                                <MenuItem
                                    className={this.state.activeMenuItem === "/menu/legalUser" ? "active" : ""}
                                    onClick={() => this.handleMenuItemClick("/menu/legalUser")}
                                    icon={<img src={legal} alt="legal" />}
                                    component={<Link to="/menu/legalUser" />}
                                    data-test-id="legal"
                                >
                                    <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                                        Legal
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    className={this.state.activeMenuItem === "/menu/userSettings" ? "active" : ""}
                                    onClick={() => this.handleMenuItemClick("/menu/userSettings")}
                                    icon={<img src={settings} alt="settings" />}
                                    component={<Link to="/menu/userSettings" />}
                                    data-test-id="settings"
                                >
                                    <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                                        Settings
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    className={this.state.activeMenuItem === "/menu/support" ? "active" : ""}
                                    onClick={() => this.handleMenuItemClick("/menu/support")}
                                    icon={<img src={support} alt="settings" />}
                                    component={<Link to="/menu/support" />}
                                    data-test-id="support"
                                >
                                    <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                                        Support
                                    </Typography>
                                </MenuItem>

                                <MenuItem style={{ border: "1px solid #57534E", padding: "0px" }}
                                    onClick={() => {
                                        localStorage.clear()
                                    }}
                                    data-test-id="logout"
                                    icon={<img src={logout} alt="logout" />}
                                    component={<Link to="/" />}>
                                    <Typography variant='body2' style={{ fontWeight: 700, fontSize: '16px' }}>{!this.state.collapsed && "Log Out"}</Typography>
                                </MenuItem>
                            </Menu>
                        </StyledWrapper>
                    </Sidebar>
                    <img data-test-id="icon" onClick={() => this.setState({ collapsed: !this.state.collapsed })}
                        style={{
                            position: "relative",
                            right: this.state.collapsed ? "10%" : "5%",
                            top: "45%",
                            zIndex: "99",
                            height: "25px",
                            width: "25px"
                        }}
                        src={this.state.collapsed ? right : left} />
                </div>
                <div className="content" style={contentStyle}>
                    <div style={headerStyle}>
                        <Typography variant='subtitle1' style={{
                            color: "#C2B30A", fontWeight: "700", textTransform: "uppercase",
                            fontSize: "16px",
                            textAlign: "left"
                        }}>DISHES</Typography>
                    </div>
                    <div style={routingStyle}>
                        <Switch>
                            <Route path="/menu/dishes" exact component={Dishes} />
                            <Route path="/menu/bakers" exact component={Dishes} />
                        </Switch>
                    </div>

                </div>
            </div>
        );
    }
}

const StyledWrapper = styled(Menu)({
    "& .active": {
        backgroundColor: "#57534E",
        color: "#C2B30A",
        fontWeight: "bold",
        borderRadius: "8px",
        margin: "10px 14px",
    },
    "& li.active": {
        width: "auto",
        "& a": {
            marginLeft: "0"
        }
    },
    "& .active img": {
        filter: "brightness(0) saturate(100%) invert(54%) sepia(36%) saturate(547%) hue-rotate(10deg) brightness(99%) contrast(99%)"
    }

})
const headerStyle = {
    backgroundColor: "#292524",
    height: "79px",
    padding: "16px 32px 16px 32px",
    borderRadius: "8px 8px 8px 8px",
    margin: "0px 25px 25px 0px",
    transition: 'margin-left 0.3s ease',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#ffffff"
};

const contentStyle: React.CSSProperties = {
    flex: "1 1 auto",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
};

const routingStyle = {
    ...headerStyle,
    height: "calc(100vh - 190px)",
};

// Customizable Area End
