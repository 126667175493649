import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface UserObj {
  first_name: string;
  last_name: string;
  email: string;
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  arrayHolder: any;
  token: string;
  collapsed: boolean;
  activeMenuItem: string;
  userObj: UserObj,
}

interface SS {
  id: any;
}

export default class UserDashboardController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  getDishesListApiCallId: any = "";
  getEditApiCallId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.state = {
      arrayHolder: [],
      token: "",
      collapsed: false,
      activeMenuItem: "/menu/dishes",
      userObj: {
        first_name: '',
        last_name: '',
        email: '',
      },

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    super.componentDidMount();
  }

  handleMenuItemClick = (path: string) => {
    this.setState({ activeMenuItem: path });
  };
}

// Customizable Area End

