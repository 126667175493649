import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { imgBell } from "./assets";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController.web";

// Customizable Area End

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box sx={{ width: '100%', height: "100%", overflowY: "auto" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
            <Typography variant="h6" sx={{
              fontSize: '28px',
              fontWeight: 700,
            }}>
              <span style={{ color: "#ffffff", marginLeft: "3px" }}>
                Notifications
              </span>
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
            <Tabs
              value={this.state.tab}
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: '#C2B30A',
                },
              }}
              onChange={(event, value) => this.handleChange(event, value)}
              aria-label="secondary tabs example"

              data-test-id="tab"
            >
              <Tab value="one"
                sx={{
                  color: '#ffffff',
                  textTransform: 'none',
                  fontWeight: '700',
                  '&.Mui-disabled': {
                    color: '#78716C',
                    opacity: 1,
                    pointerEvents: 'none',
                  },
                  '&.Mui-selected': {
                    color: '#ffffff',
                  },
                }} label="All" />
              <Tab value="two"
                sx={{
                  color: '#ffffff',
                  fontWeight: '700',
                  textTransform: 'none',
                  '&.Mui-disabled': {
                    pointerEvents: 'none',
                    color: '#78716C',
                    opacity: 1,
                  },
                  '&.Mui-selected': {
                    color: '#ffffff',
                    fontWeight: "bold"
                  },
                  width: "10%"
                }} label="Read" />
              <Tab value="three"
                sx={{
                  color: '#ffffff',
                  textTransform: 'none',
                  fontWeight: '700',
                  '&.Mui-disabled': {
                    color: '#78716C',
                    pointerEvents: 'none',
                    opacity: 1,
                  },
                  '&.Mui-selected': {
                    color: '#ffffff',
                  },
                  width: "10%"
                }} label="Unread" />
            </Tabs>

            <Typography
              variant="h6" sx={{
                color: "#A8A29E",
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: "16px",
                textDecoration: "underline",
                textUnderlineOffset: "0.5em",
                cursor: "pointer"
              }}
              onClick={this.markAllRead}
              data-test-id="markAllRead"
            >
              Mark all as read
            </Typography>
          </Box>

          <Divider sx={{ borderColor: "#57534E", marginY: 2 }} />

          {this.state.tab === "one" && <Box>
            {this.state.notifications.map((notification) => (
              <div>
                <Box
                  key={notification.id}
                  sx={{
                    display: 'flex',
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 3,

                  }}
                >
                  <img
                    style={{ width: 48, height: 48, borderRadius: '4px' }}
                    alt="Live from space album cover"
                    src={require("../assets/dish.png")}
                  />
                  <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          fontSize: '12px',
                        }}
                      >
                        New Order #{notification.id}
                      </Typography>
                      <Box
                        sx={{
                          width: '8px',
                          display: 'inline-block',
                          height: '8px',
                          backgroundColor: notification.is_read ? 'none' : '#C2B30A',
                          borderRadius: '50%',
                        }}
                      />
                    </Box>
                    <List
                      sx={{
                        listStyleType: 'disc',
                        color: "#A8A29E",
                        display: "flex",
                        fontSize: '10px',
                        fontWeight: 400,
                        padding: 0,
                        width: "35%",
                      }}
                    >
                      <ListItem sx={{ padding: 0 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 400,
                            fontSize: '10px',
                          }}
                        >
                          {this.calculateRelativeTime(notification.created_at)}
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item', padding: 0 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 400,
                            fontSize: '10px',
                          }}
                        >
                          {notification.first_name} {notification.last_name}
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item', padding: 0 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 400,
                            fontSize: '10px',
                          }}
                        >
                          Pick up: 17:00 pm
                        </Typography>
                      </ListItem>
                    </List>
                  </Box>
                </Box>
                <Box
                  sx={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                  }}
                  onClick={() => this.goToOrders(notification.orderId)}
                  data-test-id="btn1"
                >
                  {notification.is_read ? (
                    <Button
                      sx={{
                        padding: '8px 16px',
                        borderRadius: '8px',
                        backgroundColor: 'transparent',
                        color: '#ffffff',
                        border: "1px solid #ffffff",
                        '&:hover': {
                          border: "1px solid #ffffff",
                          backgroundColor: 'transparent',
                        },
                        textTransform: 'none',
                        fontSize: '10px',
                        fontWeight: 700,
                        float: "right",
                      }}
                    >
                      See order
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        float: "right",
                        padding: '8px 16px',
                        borderRadius: '8px',
                        fontSize: '10px',
                        color: '#ffffff',
                        backgroundColor: '#C2B30A',
                        '&:hover': {
                          backgroundColor: '#a59a00',
                        },
                        fontWeight: 700,
                        textTransform: 'none',
                      }}
                    >
                      See order
                    </Button>
                  )}
                </Box>
                <Divider sx={{ borderColor: "#57534E", marginY: 2 }} />
              </div>
            ))}
          </Box>}

          {this.state.tab === "two" && (
            <Box>
              {this.state.notifications
                .filter(notification => notification.is_read)
                .map((notification, index) => (
                  <Box key={index}>
                    <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center", gap: 3 }}>
                      <img
                        style={{ width: 48, borderRadius: '4px', height: 48 }}
                        src={require("../assets/dish.png")}
                        alt="Live from space album cover"
                      />
                      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '12px' }}>
                            New Order #{notification.id}
                          </Typography>
                        </Box>
                        <List sx={{ listStyleType: 'disc', color: "#A8A29E", fontSize: '10px', width: "35%", fontWeight: 400, display: "flex", padding: 0 }}>
                          <ListItem sx={{ padding: 0 }}>
                            <Typography variant="h6" sx={{ fontSize: '10px', fontWeight: 400 }}>
                              {this.calculateRelativeTime(notification.created_at)}
                            </Typography>
                          </ListItem>
                          <ListItem sx={{ display: 'list-item', padding: 0 }}>
                            <Typography variant="h6" sx={{ fontSize: '10px', fontWeight: 400 }}>
                              {notification.first_name} {notification.last_name}
                            </Typography>
                          </ListItem>
                          <ListItem sx={{ display: 'list-item', padding: 0 }}>
                            <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '10px' }}>
                              Pick up: 17:00 pm
                            </Typography>
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        data-test-id="btn2"
                        onClick={() => this.goToOrders(notification.orderId)}
                        sx={{
                          borderRadius: '8px',
                          float: "right",
                          padding: '8px 16px',
                          color: '#ffffff',
                          border: "1px solid #ffffff",
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'transparent',
                            border: "1px solid #ffffff",
                          },
                          fontSize: '10px',
                          fontWeight: 700,
                          textTransform: 'none'
                        }}
                      >
                        See order
                      </Button>
                    </Box>
                    <Divider sx={{ borderColor: "#57534E", marginY: 2 }} />
                  </Box>
                ))}
            </Box>
          )}

          {this.state.tab === "three" &&
            <Box>
              {this.state.notifications
                .filter(notification => !notification.is_read)
                .map((notification, index) => (
                  <div key={index}>
                    <Box
                      sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center", gap: 3 }}>
                      <img
                        alt="dish"
                        style={{ width: 48, height: 48, borderRadius: '4px' }}
                        src={require("../assets/dish.png")}
                      />
                      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Box style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                          <Typography
                            variant="h6" sx={{
                              fontWeight: 700,
                              fontSize: '12px',
                            }}>
                            New Order #{notification.id}
                          </Typography>
                          <Box
                            sx={{
                              display: 'inline-block',
                              width: '8px',
                              height: '8px',
                              backgroundColor: '#C2B30A',
                              borderRadius: '50%',
                            }}
                          />
                        </Box>
                        <List sx={{
                          listStyleType: 'disc',
                          color: "#A8A29E",
                          padding: 0,
                          fontSize: '10px',
                          fontWeight: 400,
                          display: "flex",
                          width: "35%"
                        }}>
                          <ListItem sx={{ padding: 0 }}>
                            <Typography
                              variant="h6" sx={{
                                fontWeight: 400,
                                fontSize: '10px',
                              }}>
                              {this.calculateRelativeTime(notification.created_at)}
                            </Typography>
                          </ListItem>
                          <ListItem sx={{ display: 'list-item', padding: 0 }}>
                            <Typography
                              variant="h6" sx={{
                                fontSize: '10px',
                                fontWeight: 400,
                              }}>
                              {notification.first_name} {notification.last_name}
                            </Typography>
                          </ListItem>
                          <ListItem sx={{ display: 'list-item', padding: 0 }}>
                            <Typography
                              variant="h6" sx={{
                                fontWeight: 400,
                                fontSize: '10px',
                              }}>
                              Pick up: 17:00 pm
                            </Typography>
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                      onClick={() => this.goToOrders(notification.orderId)}
                      data-test-id="btn3"
                    >
                      <Button
                        sx={{
                          fontSize: '10px',
                          fontWeight: 700,
                          textTransform: 'none',
                          float: "right",
                          padding: '8px 16px',
                          borderRadius: '8px',
                          color: '#ffffff',
                          backgroundColor: '#C2B30A',
                          '&:hover': {
                            backgroundColor: '#a59a00',
                          },
                        }}
                      > See order
                      </Button>
                    </Box>
                    <Divider sx={{ borderColor: "#57534E", marginY: 2 }} />
                  </div>
                ))
              }
            </Box>
          }


        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  itemWrapper: {
    border: "1px solid #767676",
    display: "flex",
    width: "70%",
    flexDirection: "row" as "row",
    marginTop: 10,
    padding: 16,
    paddingTop: 10,
    cursor: "pointer",
  },
  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row" as "row",
  },
  iconStyle: {
    width: 20,
    height: 26,
    marginTop: 6,
  },
  itemHeading: {
    color: "#000",
    flex: 1,
    fontWeight: 700,
  },
  itemHeadingRead: {
    color: "#6200EE",
    flex: 1,
    fontWeight: 700,
  },
  contents: {
    fontSize: 16,
    color: "#767676",
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },
};
// Customizable Area End
