import React from "react";
// Customizable Area Start
import { Box, Button, Typography } from "@mui/material";


// Customizable Area End
import DishesController, {
  Props,

} from "./DishesController.web";

export default class Dishes extends DishesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
  
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box sx={{ width: '100%', height: "100%", overflowY:'hidden', overflowX:'hidden'}}>
        <Typography variant="h6" sx={{
          fontSize: '28px',
          fontWeight: 700,
        }}>
          <span style={{ color: "#ffffff", marginLeft: "3px" }}>
            Dishes coming soon
          </span>
        </Typography>
        <div>
        <Button 
          data-test-id="inprogress" 
          onClick={this.handleClick}
        >
          {this.state.buttonText}
        </Button>
      </div>
        
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
